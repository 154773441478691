
import { Vue, Options } from 'vue-class-component';
import HomeMonitoringProgram from '@/lib/components/Programs/CopdProgram/HomeMonitoringProgram.vue';

@Options({
  props: {
    patient: {
      type: Object,
      required: true
    },
    organisationId: {
      type: String,
      required: true
    },
    programId: {
      type: String,
      required: true
    }
  },
  components: {
    HomeMonitoringProgram
  }
})
export default class PatientHomeMonitoringPage extends Vue {
}
